
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'CustomerDetail'
})
export default class extends Vue {
  private info: any = {}

  get id () {
    return this.$route.params.id
  }

  created () {
    this.getData()
  }

  getData () {
    this.$axios.get(this.$apis.privatization.selectPrivatizationByServiceId, { serviceId: this.id }).then((res) => {
      this.info = res
      this.info.contactList = [{
        serviceContactName: this.info.serviceContactName,
        serviceContactMobile: this.info.serviceContactMobile
      }]
    })
  }

  // 更新
  updateUseNum () {
    this.$axios.post(this.$apis.privatization.updatePrivatizationNum, { serviceId: this.id }).then(() => {
      this.getData()
      this.$message({
        message: '已使用数量更新成功!',
        type: 'success'
      })
    })
  }
}
